// Start script of Shop

import $ from 'jquery';

$('[name*="sylius_add_to_cart[cartItem][variant]"]').on('change', () => {
  let options = '';

  $('#sylius-product-adding-to-cart select').each((index, select) => {
    options += `${$(select).find('option:selected').val()} `;
  });

  const optionsArray = options.trim().split(' ');

  $('[data-variant-options]').each((index, element) => {
    const imageOptions = $(element).attr('data-variant-options');
    const imageHasOptions = optionsArray.every(option => imageOptions.indexOf(option) > -1);

    if (imageHasOptions) {
      $(element).closest('div.ui.image').parent().show();
    } else {
      $(element).closest('div.ui.image').parent().hide();
    }
  });
});

$('[name="sylius_add_to_cart[cartItem][variant]"]').on('change', (event) => {
  const variantCode = $(event.currentTarget).attr('value');

  $(`[data-variant-code*="${variantCode}"]`).each((index, element) => {
    $(element).closest('div.ui.image').parent().show();
  });
  $('.sylius-image-variants').each((index, element) => {
    if ($(element).find(`[data-variant-code*="${variantCode}"]`).length === 0) {
      $(element).closest('div.ui.image').parent().hide();
    }
  });
});

// End script of Shop
